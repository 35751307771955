<script>
import SunCalc from "suncalc";
import findClosestIndex from "find-closest";

// https://codepen.io/anowodzinski/pen/ZWKXPQ
function MoonPainter(canvas) {
  this.lineWidth = 5;
  this.radius = canvas.width / 2 - this.lineWidth / 2;
  this.offset = this.lineWidth / 2;

  this.canvas = canvas;
  this.ctx = canvas.getContext("2d");
}

MoonPainter.prototype = {
  _drawDisc: function () {
    this.ctx.translate(this.offset, this.offset);
    this.ctx.beginPath();
    this.ctx.arc(this.radius, this.radius, this.radius, 0, 2 * Math.PI, true);
    this.ctx.closePath();
    this.ctx.fillStyle = "#cacaca"; //'#fff';
    this.ctx.strokeStyle = "#cacaca"; //'#fff';
    this.ctx.lineWidth = this.lineWidth;

    this.ctx.fill();
    this.ctx.stroke();
  },

  _drawPhase: function (phase) {
    this.ctx.beginPath();
    this.ctx.arc(
      this.radius,
      this.radius,
      this.radius,
      -Math.PI / 2,
      Math.PI / 2,
      true
    );
    this.ctx.closePath();
    this.ctx.fillStyle = "#000";
    this.ctx.fill();

    this.ctx.translate(this.radius, this.radius);
    this.ctx.scale(phase, 1);
    this.ctx.translate(-this.radius, -this.radius);
    this.ctx.beginPath();
    this.ctx.arc(
      this.radius,
      this.radius,
      this.radius,
      -Math.PI / 2,
      Math.PI / 2,
      true
    );
    this.ctx.closePath();
    this.ctx.fillStyle = phase > 0 ? "#cacaca" : "#000";
    this.ctx.fill();
  },

  /**
   * @param {Number} The phase expressed as a float in [0,1] range .
   */
  paint(phase) {
    this.ctx.save();
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    if (phase <= 0.5) {
      this._drawDisc();
      this._drawPhase(4 * phase - 1);
    } else {
      this.ctx.translate(
        this.radius + 2 * this.offset,
        this.radius + 2 * this.offset
      );
      this.ctx.rotate(Math.PI);
      this.ctx.translate(-this.radius, -this.radius);

      this._drawDisc();
      this._drawPhase(4 * (1 - phase) - 1);
    }

    this.ctx.restore();
  },
};

export default {
  name: "MooninfoCard",
  props: ["log"],
  data() {
    return {
      stageSize: 50,
    };
  },
  computed: {
    moon() {
      if (!this.log.datetime) {
        return null;
      }
      return SunCalc.getMoonIllumination(new Date(this.log.datetime));
    },
    moonAge() {
      if (!this.moon) {
        return null;
      }
      // calculate moon age
      // full orbit of the moon takes 27 days, 7 days, 43 minutes or 39343 minutes in total
      // The Moon takes 27.3 days to orbit Earth, but the lunar phase cycle (from new Moon to new Moon) is 29.5 days. The Moon spends the extra 2.2 days "catching up" because Earth travels about 45 million miles around the Sun during the time the Moon completes one orbit around Earth.
      // 27.3 * 24 * 60 = 39312
      // 29 days, 12 hours and 0 minutes or 41760 + 720 = 42480
      var age_in_minutes = 39312 * this.moon.phase;
      return this.timeConvert(age_in_minutes);
    },
    fiMoonPhaseName() {
      if (!this.moon) {
        return null;
      }

      var phase_names = new Map();
      phase_names.set(0, "uusi kuu");
      phase_names.set(0.125, "kasvava sirppi");
      phase_names.set(0.25, "puolikuu (1. neljännes)");
      phase_names.set(0.375, "kasvava puolikuu");
      phase_names.set(0.5, "täysi kuu");
      phase_names.set(0.652, "vähenevä täysikuu");
      phase_names.set(0.75, "puolikuu (viimeinen neljännes)");
      phase_names.set(0.875, "vähenevä sirppi");

      var phase_names_index = Array.from(phase_names.keys());

      return phase_names.get(
        findClosestIndex(phase_names_index, this.moon.phase)
      );
    },
  },
  methods: {
    timeConvert(minutes) {
      var days = 0;
      var hours = Math.floor(minutes / 60);
      if (hours >= 24) {
        days = Math.floor(hours / 24);
        hours = hours - days * 24;
      }
      var calc_minutes = minutes % 60;
      return {
        days: days,
        hours: hours,
        minutes: calc_minutes,
      };
    },
    paintTheMoon() {
      if (this.moon) {
        var canvas = document.getElementById("canvas");
        // draws the moon to canvas
        var painter = new MoonPainter(canvas);
        painter.paint(this.moon.phase);
      }
    },
  },
  watch: {
    moon: {
      handler() {
        this.paintTheMoon()
      },
    },
  },
  mounted() {
    this.paintTheMoon()
  }
};
</script>

<template>
  <div class="col">
    <div class="card">
      <div class="card-body placeholder-glow">
        <h5 class="card-title">Kuu</h5>
        <div class="card-text" :class="{ placeholder: !log.datetime }">
          <canvas id="canvas" :width="stageSize" :height="stageSize" />
          <span v-if="moon">Vaiheessa {{ fiMoonPhaseName }} kuulla on ikää
            {{ moonAge.days }} päivää ja {{ moonAge.hours }} tuntia.</span>
          <span v-if="!log">Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#canvas {
  float: left;
  margin-right: 10px;
  margin-bottom: 20px;
}
</style>